<template>
  <div class="rows">
    <div class="col-12 col-lg-6 c g" v-for="c in classes" :key="c.classname">
    <div class="card">
      <div class="card-body">
        <h4>
            {{ c.classname }}
        </h4>
        <div class="form-group">
          <label for="">تغيير الى:</label>
          <input type="text"
            class="form-control" v-model="sd[c.classname]" @change="cc()" @keyup="cc()">
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classnames: localStorage.getItem("classnames")
        ? JSON.parse(localStorage.getItem("classnames"))
        : {
          },
        classes: [],
        classesObject: {},
        sd: {}
    };
  },
  created(){
    var g = this;
    console.log(g.classnames)
    $.post(api + "/committees/students", {
      jwt: user.jwt,
    })
      .then(function (r) {
        var ss = 1;
        if (r.status == 100) {
          g.students = r.response;
          //
          r.response.forEach((student) => {
            if (!g.classesObject[student.classname]) {
              var classrooms = [];
              r.response.forEach((student2) => {
                if (
                  student2.classname == student.classname &&
                  !classrooms.includes(student2.classroom)
                ) {
                  classrooms.push(student2.classroom);
                }
              });
              classrooms.sort(function(a, b){return a-b});
              g.classesObject[student.classname] = classrooms;
              if(!g.sd[student.classname]){
                g.sd[student.classname] = student.classname
                for (const property in g.classnames) {
                g.sd[student.classname] = g.sd[student.classname].replace(
                    g.classnames[property].number,
                    g.classnames[property].name
                );
                }
              }
              g.classes.push({
                classname: student.classname,
                classrooms: classrooms,
              });
            }
          });
        }
        })
  },
  methods: {
    cc(){
        var g = this;
        localStorage.setItem("classnames", JSON.stringify(g.classnames.map(function(x){
            x.name = g.sd[x.number]
            return x;
        })))
    }
  },
};
</script>

<style>
</style>